import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Error404 from "../../svg/Error404.svg";

const browser = typeof window !== "undefined" && window;

export default () => {
  return (
    browser && (
      <Layout>
        <div className="w-full min-h-full bg-gradient-to-b from-white to-blue-100">
          <div className="container h-full pt-10 pb-10 mx-auto align-middle lg:pb-64">
            <div className="">
              <h1 className="mx-12 text-base text-center lg:text-2xl">Lo sentimos, no se pudo encontrar la página que buscaba.</h1>
            </div>
            <div className="py-10">
              <Error404 className="w-3/4 mx-auto" />
            </div>
            <div className="pt-10 text-center">
              <p>
                <Link to="/" title="Haga clic para ir a la página principal">
                  Haga clic para ir a la página principal
                </Link>
              </p>
              <p className="text-blue-600">O</p>
              <p>
                <Link to="/blog" title="Haga clic para ir a la página del blog">
                  Lea cómo puede obtener mejores ofertas en nuestra página de Blog
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
};
